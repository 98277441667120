import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, Typography } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { TextField } from '@mui/material'
import { Chip } from '@mui/material'

const CustomAutocomplete = (props) => {
  const fieldName = props.fieldName
  const fieldLabel = props.label
  const list = props.list
  const fieldValues = props.values
  const listKey = props.listKey
  const listDescription = props.description
  const customOnChange = props.customOnChange
  const [currValue, setCurrValue] = useState([])

  const optionForLabel = (label) => {
    if (typeof label === 'object' && !Array.isArray(label) && label !== null) {
      return label[listDescription]
    }
    let toReturn = list.find((source) => {
      return source[listKey] == label
    })
    if (toReturn) {
      return toReturn[listDescription]
    }
    return `${label}`
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <Autocomplete
        disabled={props.disabled ? props.disabled : false}
        disableCloseOnSelect={true}
        name={fieldName}
        // options={
        //   fieldValues
        //     ? list.filter((x) => !fieldValues.map(String).includes(x[listKey]?.toString()))
        //     : list
        // }
        options={list}
        value={customOnChange ? (fieldValues ? fieldValues : currValue) : currValue}
        // getOptionSelected={(option, value) => value === option[listKey]}
        isOptionEqualToValue={(option, value) =>
          value === option[listKey].toString() || value === parseInt(option[listKey])
        }
        multiple
        onChange={(event, newValue) => {
          const newList = newValue ? newValue.map((v) => (v[listKey] ? v[listKey] : v)) : []

          setCurrValue(newList)
          if (customOnChange) {
            customOnChange(newList, fieldName, event)
          }
        }}
        renderOption={(props, option) => {
          const isSelected =
            fieldValues.includes(option[listKey].toString()) ||
            fieldValues.includes(parseInt(option[listKey]))
          return (
            <li {...props} style={{ backgroundColor: isSelected ? '#f0f0f0' : 'white' }}>
              <Typography color={isSelected ? 'textSecondary' : 'textPrimary'}>
                {option[listDescription]}
              </Typography>
            </li>
          )
        }}
        getOptionLabel={optionForLabel}
        renderInput={(params) => <TextField {...params} label={fieldLabel} variant="standard" />}
        renderTags={(tagValue, getTagProps) => {
          const filteredList = list.filter(
            (v) =>
              tagValue.includes(v[listKey].toString()) || tagValue.includes(parseInt(v[listKey])),
          )

          return (
            <div>
              {filteredList.map((value, index) => {
                return (
                  <Chip
                    // key={`${value[listKey]}-${index}`}
                    key={value[listKey]}
                    label={value[listDescription]}
                    style={{ marginRight: '5px' }}
                    {...getTagProps({ index })}
                  />
                )
              })}
            </div>
          )
        }}
      />
      {currValue.map((v, index) => (
        <input key={`${fieldName}-${v}-${index}`} type="hidden" name={`${fieldName}[]`} value={v} />
      ))}
    </FormControl>

    // <FormControl style={{ width: '100%' }}>
    //   <Autocomplete
    //     disabled={props.disabled ? props.disabled : false}
    //     // disableCloseOnSelect={true}
    //     name={fieldName}
    //     options={list} // Options List
    //     value={customOnChange ? fieldValues : currValue}
    //     getOptionSelected={(option, value) => value == option[listKey]}
    //     multiple
    //     onChange={(event, newValue) => {
    //       const newList = newValue ? newValue.map((v) => (v[listKey] ? v[listKey] : v)) : []
    //       setCurrValue(newList)
    //       if (customOnChange) {
    //         customOnChange(newList, fieldName, event)
    //       }
    //     }}
    //     getOptionLabel={(option) => option[listDescription]}
    //     renderInput={(params) => <TextField {...params} label={fieldLabel} variant="standard" />}
    //     renderTags={(tagValue) => {
    //       const filteredList = list.filter(
    //         (v) => tagValue.includes(v[listKey]) || tagValue.includes(`${v[listKey]}`),
    //       )
    //       return (
    //         <div>
    //           {filteredList.map((value, index) => (
    //             <Chip
    //               key={`${value[listKey]}-${index}`}
    //               label={value[listDescription]}
    //               style={{ marginRight: '5px' }}
    //             />
    //           ))}
    //         </div>
    //       )
    //     }}
    //   />
    //   {currValue.map((v, index) => (
    //     <input key={`${fieldName}-${v}-${index}`} type="hidden" name={`${fieldName}[]`} value={v} />
    //   ))}
    // </FormControl>
  )
}
CustomAutocomplete.propTypes = {
  fieldName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  values: PropTypes.any.isRequired,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any.isRequired,
  disabled: PropTypes.any,
}

export default CustomAutocomplete
