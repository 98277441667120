export const permittedProfileForms = JSON.parse(localStorage.getItem('profile_forms'))

export const checkPagePermission = (pageCode, permissionType) => {
  const pageData = permittedProfileForms?.filter((v) => v.code == pageCode)

  if (pageData?.length === 0) {
    return false
  }
  return pageData && pageData[0] ? pageData[0][permissionType] == 1 : null
}
