import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
function GlobalEnterKeyListener({ onEnterPress }) {
  useEffect(() => {
    // Function to call when the Enter key is pressed
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        console.log('Enter key pressed globally.')
        onEnterPress() // Trigger the provided action
      }
    }

    // Adding the event listener to the window object
    window.addEventListener('keydown', handleKeyDown)

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onEnterPress]) // Dependencies array, re-bind if onEnterPress changes

  return null // This component does not render anything
}
GlobalEnterKeyListener.propTypes = {
  onEnterPress: PropTypes.any,
}
export default GlobalEnterKeyListener
