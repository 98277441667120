import React, { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import {
  CModalTitle,
  CModalHeader,
  CModalBody,
  CModal,
  CModalFooter,
  CCol,
  CRow,
  CForm,
  CButton,
  CFormLabel,
  CFormInput,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCloudUpload, cilImagePlus } from '@coreui/icons'
import { toast } from 'react-toastify'
import CustomButton from 'src/components/Inputs/CustomButton'
import handleErrors from 'src/helpers/handleErrors'
import SingleCustomAutocomplete from 'src/components/CustomAutocomplete/SingleCustomAutocomplete'
import {
  CustomDate,
  CustomInput,
  InLineCustomCheckbox,
} from 'src/components/Inputs/CustomInputForms'
import CustomAutocomplete from 'src/components/CustomAutocomplete/CustomAutocomplete'
import Swal from 'sweetalert2'
import CustomSelectAutocomplete from 'src/components/CustomAutocomplete/CustomSelectAutocomplete'
import { checkPagePermission } from 'src/helpers/FixedData'
import moment from 'moment'

const AddUpdate = (props) => {
  const [saveIsLoading, setSaveIsLoading] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [positions, setPositions] = useState([])
  const [departments, setDepartments] = useState([])

  const [profileImage, setProfileImage] = useState({})

  const [attachment1, setAttachment1] = useState({})
  const [attachment2, setAttachment2] = useState({})

  const [employees, setEmployees] = useState([])
  const [legalEntities, setLegalEntities] = useState([])
  const [countries, setCountries] = useState([])

  const defaultValues = {
    id: -1,
    name: '',
    email: '',
    personal_email: '',
    password: props.signup ? '123456' : '',
    position_id: '',
    position: '',
    profile_id: '',
    department_id: '',
    title: '',
    first_name: '',
    father_first_name: '',
    last_name: '',
    mother_first_name: '',
    mother_maiden_name: '',
    spouse_name: '',
    kids: '', // 0 = no kids
    children_details: '',
    mobile_number: '',
    landline_number: '',
    address: '',
    date_of_birth: null,
    place_of_birth: '',
    country: '',
    city: '',
    emergancy_name: '',
    emergency_number: '',
    car_plate: '',
    civil_status: '',
    employment_date: moment().format('YYYY-MM-DD'),
    employment_type: '',
    resident_registration_number: '',
    nssf_number: '',
    blood_type: '',
    copy_of_id: '',
    passport_pictures: '',
    personal_family_status: '',
    criminal_records: '',
    proof_of_residence: '',
    social_security_papers: '',
    cv: '',
    certificates_and_training: '',
    passport_photo: '',
    whatsapp_group: '',
    active: 1,
    team: [],
    profile_image: '',
    country_id: '',
    leave_reason: '',
    last_employment_date: null,
  }

  const [formData, setFormData] = useState(defaultValues)

  const getEmployees = (type) => {
    if (!type || type == 3) return
    axios(`${process.env.REACT_APP_API_BASE_URL}/masterData/admin_users`, {
      responseType: 'json',
      params: {
        type: type == 1 ? null : 'employees',
        active: 1,
        current_user: formData.id,
      },
    })
      .then(function (response) {
        setEmployees(response.data)
      })
      .catch(function (error) {})
  }

  const getCountries = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/masterData/countries`)
      .then(function (response) {
        setCountries(response.data)
      })
      .catch(function (error) {})
  }

  const getLegalEntities = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/masterData/legal_entities`)
      .then(function (response) {
        setLegalEntities(response.data)
      })
      .catch(function (error) {})
  }

  const getProfiles = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/masterData/profiles`, {
      responseType: 'json',
    })
      .then(function (response) {
        setProfiles(response.data)
      })
      .catch(function (error) {
        handleErrors(error)
      })
  }

  const getDepartments = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/masterData/departments`)
      .then(function (response) {
        setDepartments(response.data)
      })
      .catch(function (error) {})
  }
  const getPositions = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/masterData/positions`)
      .then(function (response) {
        setPositions(response.data)
      })
      .catch(function (error) {})
  }
  const getFormData = () => {
    axios(`${process.env.REACT_APP_API_BASE_URL}/masterData/admin_users/${props.id}`, {
      responseType: 'json',
    })
      .then((response) => {
        getEmployees(response.data.position_id)

        setFormData((prev) => ({
          ...prev,
          id: props.id,
          ...response.data,
          team: response.data.team?.split(','),
          email: response.data.email.replace('@auditaxadvisory.com', ''),
        }))

        if (response.data.profile_image) {
          setProfileImage((prev) => ({
            ...prev,
            id: response.data.id,
            path: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/images/${response.data.profile_image}`,
          }))
        } else {
          setProfileImage({})
        }

        if (response.data.past_employment_certificate) {
          setAttachment1((prev) => ({
            ...prev,
            id: response.data.id,
            path: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/userAttachments/${response.data.past_employment_certificate}`,
          }))
        } else {
          setAttachment1({})
        }

        if (response.data.resignation_discharge_attachment) {
          setAttachment2((prev) => ({
            ...prev,
            id: response.data.id,
            path: `${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/userAttachments/${response.data.resignation_discharge_attachment}`,
          }))
        } else {
          setAttachment2({})
        }
      })
      .catch((error) => {
        handleErrors(error)
      })
  }

  const saveForm = () => {
    setSaveIsLoading(true)

    const submitFormData = new FormData()

    for (var key in formData) {
      if (formData[key]) {
        if (key == 'name' || key == 'team' || key == 'active' || key == 'email') {
        } else {
          submitFormData.append(key, formData[key])
        }
      }
    }
    submitFormData.append('email', formData.email + '@auditaxadvisory.com')

    submitFormData.append('active', formData.active)

    if (profileImage && profileImage.file) {
      submitFormData.append('profile_image', profileImage.file)
    }

    if (attachment1 && attachment1.file) {
      submitFormData.append('past_employment_certificate', attachment1.file)
    }

    if (attachment2 && attachment2.file) {
      submitFormData.append('resignation_discharge_attachment', attachment2.file)
    }

    submitFormData.append(
      'name',
      formData.first_name + ' ' + formData.father_first_name + ' ' + formData.last_name,
    )
    submitFormData.append('team', formData.team ? formData.team.join(',') : null)

    // const submittedData = {
    //   ...formData,
    //   name: formData.first_name + ' ' + formData.father_first_name + ' ' + formData.last_name,
    //   team: formData.team ? formData.team.join(',') : null,
    // }

    if (formData.id > 0) {
      submitFormData.append('_method', 'put')
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/masterData/admin_users/${formData.id}`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success('Updated Successfully')
          setSaveIsLoading(false)
          handleClose(true)
        })
        .catch((error) => {
          setSaveIsLoading(false)
          handleErrors(error)
        })
    } else {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/masterData/admin_users`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success('Inserted Successfully')
          setSaveIsLoading(false)
          handleClose(true)
        })
        .catch((error) => {
          setSaveIsLoading(false)
          handleErrors(error)
        })
    }
  }

  const handleInputChange = (e) => {
    const { id, name, value, checked, type } = e.target
    if (type == 'checkbox') {
      if (id == 'teamEmployees') {
        if (checked) {
          setFormData((prev) => ({
            ...prev,
            team: employees.map((x) => x.id),
            [id]: checked ? 1 : 0,
          }))
        } else {
          setFormData((prev) => ({
            ...prev,
            team: [],
            [id]: checked ? 1 : 0,
          }))
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [id]: checked ? 1 : 0,
        }))
      }
    } else {
      if (props.signup) {
        if (id == 'first_name' || id == 'last_name') {
          let email = formData.email

          email = `${id == 'first_name' ? value.charAt(0) : formData.first_name?.charAt(0)}.${
            id == 'last_name' ? value : formData.last_name
          }`

          setFormData((prev) => ({
            ...prev,
            [id]: value,
            email: email,
          }))
        } else {
          setFormData((prev) => ({
            ...prev,
            [id]: value,
          }))
        }
      } else {
        setFormData((prev) => ({
          ...prev,
          [id]: value,
        }))
      }
    }
  }
  const resetForm = () => {
    setFormData(defaultValues)
    setProfileImage({})
  }

  const customOnChange = (newValue, name) => {
    if (name == 'position_id') {
      getEmployees(newValue)
    }
    setFormData((prev) => ({
      ...prev,
      [name]: newValue,
    }))
  }

  const handleChangeFile = (e, type) => {
    const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000)
    e.preventDefault()
    const reader = new FileReader()
    const file = e.target.files[0]

    reader.readAsDataURL(file)
    reader.onloadend = () => {
      if (type == 'profile') setProfileImage({ id: randomId, path: reader.result, file: file })

      if (type == 'attachment1') setAttachment1({ id: randomId, path: reader.result, file: file })

      if (type == 'attachment2') setAttachment2({ id: randomId, path: reader.result, file: file })
    }
  }

  const removeFile = (id, type) => {
    if (id > 0) {
      Swal.fire({
        title: 'Please Confirm Your Action ?',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        icon: 'warning',
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/masterData/admin_users/deleteUserFile`,
            data: { id: id, type: type },
          })
            .then((response) => {
              if (type == 'profile_image') setProfileImage({})

              if (type == 'attachment1') setAttachment1({})

              if (type == 'attachment2') setAttachment2({})

              toast.success(`Success`)
            })
            .catch((error) => {
              toast.error(`Error`)
            })
        }
      })
    } else {
      if (type == 'profile_image') setProfileImage({})

      if (type == 'attachment1') setAttachment1({})

      if (type == 'attachment2') setAttachment2({})
    }
  }

  useEffect(() => {
    if (checkPagePermission('master_data_users_admin_section', 'can_view')) {
      // if (profiles.length == 0) getProfiles()
      // if (departments.length == 0) getDepartments()
      // if (positions.length == 0) getPositions()

      // if (legalEntities.length == 0) getLegalEntities()
      // if (countries.length == 0) getCountries()

      const countries_stored = sessionStorage.getItem('countries')
      if (countries_stored) {
        setCountries(JSON.parse(countries_stored))
      }

      const legal_entities_stored = sessionStorage.getItem('legal_entities')
      if (legal_entities_stored) {
        setLegalEntities(JSON.parse(legal_entities_stored))
      }

      const admin_profiles_stored = sessionStorage.getItem('admin_profiles')
      if (admin_profiles_stored) {
        setProfiles(JSON.parse(admin_profiles_stored))
      }

      const departments_stored = sessionStorage.getItem('departments')
      if (departments_stored) {
        setDepartments(JSON.parse(departments_stored))
      }

      const positions_stored = sessionStorage.getItem('positions')
      if (positions_stored) {
        setPositions(JSON.parse(positions_stored))
      }
    }
    if (props.visible) {
      if (props.id > 0) {
        getFormData()
      } else {
        resetForm()
      }
    }
  }, [props.visible])

  const handleClose = (status) => {
    resetForm()
    props.handleClose(status)
  }

  return (
    <>
      <CModal backdrop="static" size="lg" visible={props.visible} onClose={handleClose}>
        <CModalHeader>
          {props.signup ? (
            <CModalTitle>Sign Up</CModalTitle>
          ) : (
            <CModalTitle>{props.id > 0 ? 'Update' : 'Add'} User </CModalTitle>
          )}
        </CModalHeader>
        <CModalBody>
          <CForm className="row g-3">
            <CRow className="g-2 mb-5">
              <CCol md={3}></CCol>
              <CCol md={6} className="input-image">
                {profileImage.id ? (
                  <div style={{ position: 'relative', width: 'fit-content' }}>
                    <img src={profileImage.path} width="200" />
                    <div style={{ position: 'absolute', top: -5, right: -40 }}>
                      <CButton
                        color={'danger'}
                        size="sm"
                        className="m-2"
                        variant="outline"
                        onClick={() => {
                          removeFile(profileImage.id, 'profile_image')
                        }}
                      >
                        x
                      </CButton>
                    </div>
                  </div>
                ) : (
                  <>
                    <CFormLabel
                      htmlFor="profile"
                      style={{
                        width: '80%',
                        padding: 20,
                        borderStyle: 'dashed',
                        cursor: 'pointer',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <p>
                          <b>PROFILE IMAGE</b>
                        </p>
                        <CIcon icon={cilCloudUpload} width={75} />
                        <p>Drag and Drop to upload image</p>
                        <b>OR</b>
                        <p>Browse file</p>
                        <CIcon icon={cilImagePlus} className="" width={50} />
                      </div>
                    </CFormLabel>
                    <CFormInput
                      className="mb-3"
                      type="file"
                      name="image"
                      id="profile"
                      onChange={(e) => handleChangeFile(e, 'profile')}
                    />
                  </>
                )}
              </CCol>
              <CCol md={3}></CCol>
            </CRow>

            <CRow className="g-1">
              <CCol md={4}>
                <CustomInput
                  fieldLabel="First Name"
                  id={'first_name'}
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
              </CCol>

              <CCol md={4}>
                <CustomInput
                  fieldLabel="Father's Name"
                  id={'father_first_name'}
                  value={formData.father_first_name}
                  onChange={handleInputChange}
                />
              </CCol>

              <CCol md={4}>
                <CustomInput
                  fieldLabel="Last Name"
                  id={'last_name'}
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>

            <CRow className="g-1">
              <CCol md={12}>
                <CustomInput
                  fieldLabel="Mother's Full Name"
                  id={'mother_first_name'}
                  value={formData.mother_first_name}
                  onChange={handleInputChange}
                />
              </CCol>

              {/* <CCol md={6}>
                <CustomInput
                  fieldLabel="Mother's Maiden Name"
                  id={'mother_maiden_name'}
                  value={formData.mother_maiden_name}
                  onChange={handleInputChange}
                />
              </CCol> */}
            </CRow>

            <CRow className="g-1">
              <CCol md={6}>
                <CustomInput
                  fieldLabel={'Personal Email'}
                  id={'personal_email'}
                  value={formData.personal_email}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>
            <CRow className="g-1">
              <CCol md={4}>
                <SingleCustomAutocomplete
                  fieldName="civil_status"
                  label="Civil Status"
                  list={[
                    { id: 'single', name: 'Single' },
                    { id: 'married', name: 'Married' },
                    { id: 'separated ', name: 'Separated ' },
                    { id: 'divorced', name: 'Divorced' },
                    { id: 'widowed', name: 'Widowed' },
                  ]}
                  value={formData.civil_status}
                  listKey="id"
                  description="name"
                  customOnChange={customOnChange}
                />
              </CCol>

              {formData.civil_status && formData.civil_status != 'single' && (
                <CCol md={4}>
                  <CustomInput
                    fieldLabel="Spouse name"
                    id={'spouse_name'}
                    value={formData.spouse_name}
                    onChange={handleInputChange}
                  />
                </CCol>
              )}
              <CCol md={formData.hasKids == 1 || formData.kids > 0 ? 1 : 4}>
                <InLineCustomCheckbox
                  label={formData.hasKids == 1 || formData.kids > 0 ? '' : 'Has Kids'}
                  checked={formData.hasKids == 1 || formData.kids > 0}
                  id={'hasKids'}
                  onClick={handleInputChange}
                />
              </CCol>

              {(formData.hasKids == 1 || formData.kids > 0) && (
                <CCol md={3}>
                  <CustomInput
                    fieldLabel="Number of kids"
                    id={'kids'}
                    value={formData.kids}
                    onChange={handleInputChange}
                  />
                </CCol>
              )}
            </CRow>
            {formData.kids > 0 && (
              <CRow className="g-1">
                <CCol md={12}>
                  <CustomInput
                    multiline
                    fieldLabel="Name and date of birth of Children"
                    id={'children_details'}
                    value={formData.children_details}
                    onChange={handleInputChange}
                  />
                </CCol>
              </CRow>
            )}

            <CRow className="g-1">
              <CCol md={6}>
                <CustomInput
                  fieldLabel="Mobile number"
                  id={'mobile_number'}
                  value={formData.mobile_number}
                  onChange={handleInputChange}
                />
              </CCol>

              <CCol md={6}>
                <CustomInput
                  fieldLabel="Landline number"
                  id={'landline_number'}
                  value={formData.landline_number}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>
            <CRow className="g-1">
              <CCol md={6}>
                <CustomInput
                  fieldLabel="Resident Registration Number"
                  id={'resident_registration_number'}
                  value={formData.resident_registration_number}
                  onChange={handleInputChange}
                />
              </CCol>

              <CCol md={6}>
                <CustomInput
                  keepCase={true}
                  fieldLabel="NSSF Number"
                  id={'nssf_number'}
                  value={formData.nssf_number}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>
            <CRow className="g-1">
              <CCol md={6}>
                <CustomDate
                  keepCase={true}
                  id="date_of_birth"
                  label="Date of Birth"
                  value={formData.date_of_birth}
                  onChange={handleInputChange}
                />
              </CCol>

              <CCol md={6}>
                <CustomInput
                  keepCase={true}
                  fieldLabel="Place of Birth"
                  id={'place_of_birth'}
                  value={formData.place_of_birth}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>

            <CRow className="g-1">
              <CCol md={12}>
                <CustomInput
                  fieldLabel="Full address"
                  id={'address'}
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>

            <CRow className="g-1">
              <CCol md={6}>
                {/* <CustomInput
                  fieldLabel="Country"
                  id={'country'}
                  value={formData.country}
                  onChange={handleInputChange}
                /> */}

                <SingleCustomAutocomplete
                  fieldName="country_id"
                  label="Country"
                  list={countries}
                  value={formData.country_id}
                  listKey="id"
                  description="name"
                  customOnChange={customOnChange}
                />
              </CCol>

              <CCol md={6}>
                <CustomInput
                  fieldLabel="City"
                  id={'city'}
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>

            <CRow className="g-1">
              <CCol md={6}>
                <CustomInput
                  fieldLabel="Emergency Name"
                  id={'emergancy_name'}
                  value={formData.emergancy_name}
                  onChange={handleInputChange}
                />
              </CCol>

              <CCol md={6}>
                <CustomInput
                  fieldLabel="Emergency number"
                  id={'emergency_number'}
                  value={formData.emergency_number}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>

            <CRow className="g-1">
              {/* <CCol md={6}>
                <CustomInput
                  fieldLabel="Car Plate"
                  id={'car_plate'}
                  value={formData.car_plate}
                  onChange={handleInputChange}
                />
              </CCol> */}

              <CCol md={6}>
                <CustomInput
                  fieldLabel="Blood Type"
                  id={'blood_type'}
                  value={formData.blood_type}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>

            <CRow className="g-1">
              <CCol md={6}>
                <CustomDate
                  id="employment_date"
                  label="Employment Date"
                  value={formData.employment_date}
                  onChange={handleInputChange}
                />
              </CCol>

              <CCol md={6}>
                {/* <CustomInput
                  fieldLabel="Employment Type"
                  id={'employment_type'}
                  value={formData.employment_type}
                  onChange={handleInputChange}
                /> */}

                <SingleCustomAutocomplete
                  fieldName="employment_type"
                  label="Employment Type"
                  list={[
                    {
                      id: 'full_time',
                      name: 'Full Time',
                    },
                    {
                      id: 'part_time',
                      name: 'Part Time',
                    },
                    {
                      id: 'trainee',
                      name: 'Trainee',
                    },
                  ]}
                  value={formData.employment_type}
                  listKey="id"
                  description="name"
                  customOnChange={customOnChange}
                />
              </CCol>
            </CRow>
            <CRow className="g-1">
              <CCol md={6}>
                <CRow className="g-1">
                  <CCol md={6}>
                    <CustomInput
                      disabled={props.signup}
                      fieldLabel={'Email'}
                      id={'email'}
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </CCol>

                  <CCol md={6}>
                    <CustomInput disabled={true} fieldLabel={' '} value={'@auditaxadvisory.com'} />
                  </CCol>
                </CRow>
              </CCol>

              <CCol md={6}>
                <CustomInput
                  type={props.id > 0 ? 'password' : ''}
                  disabled={props.id > 0 || props.signup}
                  fieldLabel="Password"
                  id={'password'}
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </CCol>
            </CRow>

            {/* <CRow className="g-1">
              <CCol md={4}>
                <InLineCustomCheckbox
                  label={'Copy of ID'}
                  checked={formData.copy_of_id == 1}
                  id={'copy_of_id'}
                  onClick={handleInputChange}
                />
              </CCol>

              <CCol md={4}>
                <InLineCustomCheckbox
                  label={'copy of passport'}
                  checked={formData.passport_pictures == 1}
                  id={'passport_pictures'}
                  onClick={handleInputChange}
                />
              </CCol>

              <CCol md={4}>
                <InLineCustomCheckbox
                  label={'Personal Family Status'}
                  checked={formData.personal_family_status == 1}
                  id={'personal_family_status'}
                  onClick={handleInputChange}
                />
              </CCol>
            </CRow>

            <CRow className="g-1">
              <CCol md={4}>
                <InLineCustomCheckbox
                  label={'Criminal Records'}
                  checked={formData.criminal_records == 1}
                  id={'criminal_records'}
                  onClick={handleInputChange}
                />
              </CCol>

              <CCol md={4}>
                <InLineCustomCheckbox
                  label={'Proof Of Residence'}
                  checked={formData.proof_of_residence == 1}
                  id={'proof_of_residence'}
                  onClick={handleInputChange}
                />
              </CCol>

              <CCol md={4}>
                <InLineCustomCheckbox
                  label={'Social Security Papers'}
                  checked={formData.social_security_papers == 1}
                  id={'social_security_papers'}
                  onClick={handleInputChange}
                />
              </CCol>
            </CRow>

            <CRow className="g-1">
              <CCol md={4}>
                <InLineCustomCheckbox
                  label={'Certificates and training'}
                  checked={formData.certificates_and_training == 1}
                  id={'certificates_and_training'}
                  onClick={handleInputChange}
                />
              </CCol>

              <CCol md={4}>
                <InLineCustomCheckbox
                  label={'Passport photo'}
                  checked={formData.passport_photo == 1}
                  id={'passport_photo'}
                  onClick={handleInputChange}
                />
              </CCol>

              <CCol md={4}>
                <InLineCustomCheckbox
                  label={'Whatsapp group'}
                  checked={formData.whatsapp_group == 1}
                  id={'whatsapp_group'}
                  onClick={handleInputChange}
                />
              </CCol>
            </CRow> */}
            {checkPagePermission('master_data_users_admin_section', 'can_view') && (
              <>
                <CRow className="g-1">
                  <CCol md={6}>
                    <SingleCustomAutocomplete
                      fieldName="work_country"
                      label="Work Country"
                      list={countries}
                      value={formData.work_country}
                      listKey="id"
                      description="name"
                      customOnChange={customOnChange}
                    />
                  </CCol>

                  <CCol md={6}>
                    <SingleCustomAutocomplete
                      fieldName="nssf_employer"
                      label="NSSF Employer"
                      list={legalEntities}
                      value={formData.nssf_employer}
                      listKey="id"
                      description="name"
                      customOnChange={customOnChange}
                    />
                  </CCol>
                </CRow>

                <CRow className="g-1">
                  <CCol md={6}>
                    <SingleCustomAutocomplete
                      fieldName="department_id"
                      label="Department"
                      list={departments}
                      value={formData.department_id}
                      listKey="id"
                      description="name"
                      customOnChange={customOnChange}
                    />
                  </CCol>

                  <CCol md={6}>
                    <SingleCustomAutocomplete
                      fieldName="position"
                      label="Position"
                      list={positions}
                      value={formData.position}
                      listKey="id"
                      description="name"
                      customOnChange={customOnChange}
                    />
                  </CCol>
                </CRow>
                <CRow className="g-1">
                  <CCol md={6}>
                    <SingleCustomAutocomplete
                      fieldName="position_id"
                      label="Level"
                      list={[
                        { id: 1, name: 'Admin' },
                        { id: 2, name: 'Supervisor' },
                        { id: 3, name: 'Employee' },
                      ]}
                      value={formData.position_id}
                      listKey="id"
                      description="name"
                      customOnChange={customOnChange}
                    />
                  </CCol>
                  <CCol md={6}>
                    <SingleCustomAutocomplete
                      fieldName="profile_id"
                      label="Profile"
                      list={profiles}
                      value={formData.profile_id}
                      listKey="id"
                      description="name"
                      customOnChange={customOnChange}
                    />
                  </CCol>
                </CRow>
                {formData.position_id == 2 || formData.position_id == 1 ? (
                  <CRow className="g-1">
                    <CCol md={12}>
                      <CustomSelectAutocomplete
                        labelDisabled={false}
                        fieldName="team"
                        label={
                          <div style={{ width: '100%', borderBottom: '1px solid black' }}>
                            <InLineCustomCheckbox
                              label={<h5 style={{ margin: 0, padding: 0 }}>Team Members</h5>}
                              checked={formData.teamEmployees == 1}
                              id={'teamEmployees'}
                              onClick={handleInputChange}
                            />
                          </div>
                        }
                        list={employees}
                        values={formData.team}
                        listKey="id"
                        description="name"
                        customOnChange={customOnChange}
                      />
                    </CCol>
                  </CRow>
                ) : (
                  <> </>
                )}

                <CRow className="g-1">
                  {/* <CCol md={4}>
                <InLineCustomCheckbox
                  label={'CV'}
                  checked={formData.cv == 1}
                  id={'cv'}
                  onClick={handleInputChange}
                />
              </CCol> */}

                  <CCol md={8}></CCol>

                  <CCol md={4}>
                    <InLineCustomCheckbox
                      label={'Active'}
                      checked={formData.active == 1}
                      id={'active'}
                      onClick={handleInputChange}
                    />
                  </CCol>
                </CRow>
              </>
            )}
            {formData.active == 0 && (
              <>
                <CRow className="g-1">
                  <CCol md={12}>
                    {/* <CustomInput
                      fieldLabel={'Reason for leaving'}
                      id={'leave_reason'}
                      value={formData.leave_reason}
                      onChange={handleInputChange}
                    /> */}

                    <SingleCustomAutocomplete
                      fieldName="leave_reason"
                      label="Reason for Leaving"
                      list={[
                        { id: 'end_of_training', name: 'End of Training' },
                        { id: 'end_of_internship', name: 'End of Internship' },
                        { id: 'end_of_probation', name: 'End of Probation' },
                        { id: 'resignation', name: 'Resignation' },
                        { id: 'termination', name: 'Termination' },
                      ]}
                      value={formData.leave_reason}
                      listKey="id"
                      description="name"
                      customOnChange={customOnChange}
                    />
                  </CCol>
                </CRow>

                <CRow className="g-1">
                  <CCol md={12}>
                    <CustomDate
                      id="last_employment_date"
                      label="Last employment date"
                      value={formData.last_employment_date}
                      onChange={handleInputChange}
                    />
                  </CCol>
                </CRow>

                <CRow className="g-1 mt-5">
                  <CCol md={5} className="input-image">
                    {attachment1.id ? (
                      <div style={{ position: 'relative', width: 'fit-content' }}>
                        <p>
                          <b>Past Employment Certificate</b>
                        </p>
                        {attachment1.path.includes('/pdf') || attachment1.path.includes('.pdf') ? (
                          <object
                            data={attachment1.path}
                            type="application/pdf"
                            width="100%"
                            height="400"
                          >
                            This browser does not support PDFs. Please download the PDF to view it:{' '}
                            <a rel="noopener noreferrer" target="_blank" href={attachment1.path}>
                              Download PDF
                            </a>
                            .
                          </object>
                        ) : (
                          <img src={attachment1.path} width="100%" />
                        )}

                        <div style={{ position: 'absolute', top: 0, right: '15px' }}>
                          <CButton
                            color={'danger'}
                            size="sm"
                            className="m-2"
                            variant="outline"
                            onClick={() => {
                              removeFile(attachment1.id, 'attachment1')
                            }}
                          >
                            x
                          </CButton>
                        </div>
                      </div>
                    ) : (
                      <>
                        <CFormLabel
                          htmlFor="attachment1"
                          style={{
                            width: '80%',
                            padding: 20,
                            borderStyle: 'dashed',
                            cursor: 'pointer',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <p>
                              <b>Past Employment Certificate</b>
                            </p>
                            <CIcon icon={cilCloudUpload} width={75} />
                            <p>Drag and Drop to upload file</p>
                            <b>OR</b>
                            <p>Browse file</p>
                            <CIcon icon={cilImagePlus} className="" width={50} />
                          </div>
                        </CFormLabel>
                        <CFormInput
                          className="mb-3"
                          type="file"
                          name="image"
                          id="attachment1"
                          onChange={(e) => handleChangeFile(e, 'attachment1')}
                        />
                      </>
                    )}
                  </CCol>
                  <CCol md={2}></CCol>
                  <CCol md={5} className="input-image">
                    {attachment2.id ? (
                      <div style={{ position: 'relative', width: 'fit-content' }}>
                        <p>
                          <b>Resignation/Discharge</b>
                        </p>

                        {attachment2.path.includes('/pdf') || attachment2.path.includes('.pdf') ? (
                          <object
                            data={attachment2.path}
                            type="application/pdf"
                            width="100%"
                            height="400"
                          >
                            {' '}
                            This browser does not support PDFs. Please download the PDF to view it:{' '}
                            <a rel="noopener noreferrer" target="_blank" href={attachment1.path}>
                              Download PDF
                            </a>
                            .
                          </object>
                        ) : (
                          <img src={attachment2.path} width="100%" />
                        )}

                        <div style={{ position: 'absolute', top: 0, right: '15px' }}>
                          <CButton
                            color={'danger'}
                            size="sm"
                            className="m-2"
                            variant="outline"
                            onClick={() => {
                              removeFile(attachment2.id, 'attachment2')
                            }}
                          >
                            x
                          </CButton>
                        </div>
                      </div>
                    ) : (
                      <>
                        <CFormLabel
                          htmlFor="attachment2"
                          style={{
                            width: '80%',
                            padding: 20,
                            borderStyle: 'dashed',
                            cursor: 'pointer',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <p>
                              <b>Resignation/Discharge</b>
                            </p>
                            <CIcon icon={cilCloudUpload} width={75} />
                            <p>Drag and Drop to upload file</p>
                            <b>OR</b>
                            <p>Browse file</p>
                            <CIcon icon={cilImagePlus} className="" width={50} />
                          </div>
                        </CFormLabel>
                        <CFormInput
                          className="mb-3"
                          type="file"
                          name="image"
                          id="attachment2"
                          onChange={(e) => handleChangeFile(e, 'attachment2')}
                        />
                      </>
                    )}
                  </CCol>
                </CRow>
              </>
            )}
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CustomButton title="Save" onClick={saveForm} isLoading={saveIsLoading} />
        </CModalFooter>
      </CModal>
    </>
  )
}
AddUpdate.propTypes = {
  visible: PropTypes.any.isRequired,
  id: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  signup: PropTypes.any,
}

export default AddUpdate
