import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from '@mui/material'
import { Autocomplete } from '@mui/material'
import { TextField } from '@mui/material'
import { titleCase } from 'src/helpers/functions'

const SingleCustomAutocomplete = (props) => {
  const filedName = props.filedName
  const fieldLabel = props.label
  const list = props.list
  const fieldValue = props.value
  const listKey = props.listKey
  const listDescription = props.description
  const customOnChange = props.customOnChange
  const [currValue, setCurrValue] = useState(null)

  const optionForLabel = (label) => {
    if (typeof label === 'object' && !Array.isArray(label) && label !== null) {
      return label[listDescription]
    }
    let toReturn = list.find((source) => {
      return source[listKey] == label
    })
    if (toReturn) {
      return toReturn[listDescription]
    }
    return `${label}`
  }

  const update = (event, value) => {
    if (value === null) {
      setCurrValue(null)
      if (customOnChange) {
        customOnChange(null, filedName, event)
      }
      return
      // }else if( typeof value === 'string'){
      //     setValue(optionForLabel(value))
    } else {
      setCurrValue(value[listKey])
      if (customOnChange) {
        customOnChange(value[listKey], filedName, value[listDescription], event)
      }
    }
  }

  return (
    <FormControl style={{ width: '100%' }}>
      <Autocomplete
        disabled={props.disabled ? props.disabled : false}
        name={titleCase(filedName)}
        options={list} // Options List
        value={
          customOnChange ? (props.hasOwnProperty('value') ? fieldValue : currValue) : currValue
        }
        onChange={update}
        getOptionLabel={optionForLabel}
        isOptionEqualToValue={(option, value) => {
          return value == option[listKey]
        }}
        style={{ width: '100%' }}
        renderInput={(params) => <TextField {...params} label={fieldLabel} variant="standard" />}
      />
      {currValue && <input type="hidden" name={filedName} value={currValue} />}
    </FormControl>
  )
}

SingleCustomAutocomplete.propTypes = {
  filedName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  value: PropTypes.any,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any,
  disabled: PropTypes.any,
}

export default SingleCustomAutocomplete
