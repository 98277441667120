import React, { useState } from 'react'
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import axios from 'axios'
import cookie from 'js-cookie'
import { useDispatch } from 'react-redux'

import logo from '../../../assets/brand/logo.jpg'

import CustomButton from '../../../components/Inputs/CustomButton'
import handleErrors from '../../../helpers/handleErrors'
import allActions from '../../../actions/allActions'
import GlobalEnterKeyListener from 'src/components/CustomListeners/GlobalEnterKeyListener'
import AddUpdate from 'src/views/MasterData/AdminUsers/AddUpdate'
import { useNavigate } from 'react-router-dom'

const SignUp = () => {
  let navigate = useNavigate()
  return (
    <div style={{ backgroundColor: 'black', height: '100vh' }}>
      <img
        width={'100%'}
        style={{ maxWidth: 300 }}
        src={`${process.env.REACT_APP_API_BASE_URL_ATTACHMENTS}/logo/main.png`}
      />
      <AddUpdate
        signup={true}
        visible={true}
        id={-1}
        handleClose={() => navigate('/', { state: {} })}
      />
    </div>
  )
}

export default SignUp
