import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Grid,
  Typography,
  Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const CustomSelectAutocomplete = (props) => {
  const {
    filedName,
    label,
    list,
    values,
    listKey,
    description,
    customOnChange,
    disabled = false,
    labelDisabled,
  } = props
  const [selectedValues, setSelectedValues] = useState(values || [])

  const handleChange = (value) => {
    const currentIndex = selectedValues.map(String).indexOf(value.toString())
    const newChecked = [...selectedValues]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    console.log('===', currentIndex, newChecked, filedName)
    setSelectedValues(newChecked)
    if (customOnChange) {
      customOnChange(newChecked, filedName)
    }
  }

  useEffect(() => {
    console.log('values', values)
    setSelectedValues(values || [])
  }, [values])

  return (
    <FormControl component="fieldset" style={{ width: '100%', marginTop: 20 }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {labelDisabled ? (
            <TextField label={label} variant="standard" disabled={labelDisabled} />
          ) : (
            <Typography>{label}</Typography>
          )}
        </AccordionSummary>
        <Divider />

        <AccordionDetails>
          <FormGroup>
            <Grid container spacing={2}>
              {list.map((option, index) => (
                <Grid item xs={4} key={option[listKey]}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedValues.map(String).includes(option[listKey]?.toString())}
                        onChange={() => handleChange(option[listKey])}
                        name={`${filedName}[]`}
                        disabled={disabled}
                      />
                    }
                    label={option[description]}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </AccordionDetails>
      </Accordion>
      {selectedValues.map((value, index) => (
        <input key={index} type="hidden" name={`${filedName}[]`} value={value} />
      ))}
    </FormControl>
  )
}

CustomSelectAutocomplete.propTypes = {
  filedName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  values: PropTypes.any,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any,
  disabled: PropTypes.any,
  labelDisabled: PropTypes.any,
}

export default CustomSelectAutocomplete

// import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
// import { FormControl, FormGroup, FormControlLabel, Checkbox, TextField, Grid } from '@mui/material'

// const CustomSelectAutocomplete = (props) => {
//   const {
//     filedName,
//     label,
//     list,
//     values,
//     listKey,
//     description,
//     customOnChange,
//     disabled = false,
//     labelDisabled,
//   } = props
//   const [selectedValues, setSelectedValues] = useState(values || [])

//   const handleChange = (value) => {
//     const currentIndex = selectedValues.indexOf(value)
//     const newChecked = [...selectedValues]

//     if (currentIndex === -1) {
//       newChecked.push(value)
//     } else {
//       newChecked.splice(currentIndex, 1)
//     }

//     setSelectedValues(newChecked)
//     if (customOnChange) {
//       customOnChange(newChecked, filedName)
//     }
//   }

//   useEffect(() => {
//     setSelectedValues(values || [])
//   }, [values])

//   return (
//     <FormControl component="fieldset" style={{ width: '100%' }}>
//       {labelDisabled ? (
//         <TextField label={label} variant="standard" disabled={labelDisabled} />
//       ) : (
//         label
//       )}

//       <FormGroup>
//         <Grid container spacing={2}>
//           {list.map((option, index) => (
//             <Grid item xs={4} key={option[listKey]}>
//               <FormControlLabel
//                 control={
//                   <Checkbox
//                     checked={selectedValues.map(String).includes(option[listKey]?.toString())}
//                     onChange={() => handleChange(option[listKey])}
//                     name={`${filedName}[]`}
//                     disabled={disabled}
//                   />
//                 }
//                 label={option[description]}
//               />
//             </Grid>
//           ))}
//         </Grid>
//       </FormGroup>
//       {selectedValues.map((value, index) => (
//         <input key={index} type="hidden" name={`${filedName}[]`} value={value} />
//       ))}
//     </FormControl>
//   )
// }

// CustomSelectAutocomplete.propTypes = {
//   filedName: PropTypes.any.isRequired,
//   label: PropTypes.any.isRequired,
//   list: PropTypes.any.isRequired,
//   values: PropTypes.any,
//   listKey: PropTypes.any.isRequired,
//   description: PropTypes.any.isRequired,
//   customOnChange: PropTypes.any,
//   disabled: PropTypes.any,
//   labelDisabled: PropTypes.any,
// }

// export default CustomSelectAutocomplete
