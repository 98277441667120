import React, { useState } from 'react'
import { CButton, CSpinner } from '@coreui/react'
import PropTypes from 'prop-types'

const CustomButton = (props) => {
  return (
    <CButton
      size="sm"
      color={props.color ? props.color : 'primary'}
      className="px-4"
      onClick={props.onClick}
      disabled={props.isLoading ? true : false}
      style={props.style}
      type={'button'}
      {...props}
    >
      {props.isLoading ? <CSpinner size="sm" /> : props.title}
    </CButton>
  )
}
CustomButton.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    onClick: PropTypes.node,
    title: PropTypes.node,
    style: PropTypes.node,
    isLoading: PropTypes.bool,
  }).isRequired,
}).isRequired

export default CustomButton
